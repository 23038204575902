'use client';
import Amplify, { Auth } from 'aws-amplify';
import { appPublicConfig } from '@/configs/appPublicConfig';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore XXX: we have to ignore this type error to pass the CI typecheck, because we're not doing amplify pull on CI
import config from '../amplifyconfiguration.json';

Amplify.configure({
  ...config,
  ...(appPublicConfig.GRAPHQL_ENDPOINT_OVERRIDE
    ? {
        aws_appsync_graphqlEndpoint: appPublicConfig.GRAPHQL_ENDPOINT_OVERRIDE,
      }
    : {}),
  ssr: true,
  Auth: {
    cookieStorage: {
      domain: appPublicConfig.COOKIE_DOMAIN,
      path: '/',
      expires: 365,
      sameSite: 'lax',
      secure: true,
    },
  },
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
});

export const AmplifyConfigure: React.FC = () => null;
